
// @ is an alias to /src
import Calendar from '@/components/Calendar.vue';
import { MODULE_TYPES, ModulesTypes } from '@/store/constants';
import { CALENDAR_VIEW_TYPES } from '@/store/constants';

import Vue from 'vue';

interface Data {
  calendarInitialView: string;
}

interface Computed {
  types: ModulesTypes;
}

export default Vue.extend<
  Data,
  Record<string, never>,
  Computed,
  Record<string, never>
>({
  data: () => ({
    calendarInitialView: CALENDAR_VIEW_TYPES.MONTH,
    calendarEvents: [
      {
        name: 'Vacinação',
        moduleName: MODULE_TYPES.HEALTH.name,
        color: MODULE_TYPES.HEALTH.color,
        icon: MODULE_TYPES.HEALTH.icon,
        start: new Date('September 07, 2020 08:00:00'),
        end: new Date('September 09, 2020 17:00:00'),
        details: 'Tiago vai efetuar a vacinacao de todos os cavalos',
        timed: true
      },
      {
        name: 'Vermifugação',
        moduleName: MODULE_TYPES.HEALTH.name,
        color: MODULE_TYPES.HEALTH.color,
        icon: MODULE_TYPES.HEALTH.icon,
        start: new Date('September 07, 2020 10:30:00'),
        end: new Date('September 07, 2020 11:30:00'),
        details: 'Tiago vai efetuar a vermifugação de todos os cavalos',
        timed: true
      },
      {
        name: 'Trocar a ferradura do Pé de Pano',
        moduleName: MODULE_TYPES.HORSESHOE.name,
        color: MODULE_TYPES.HORSESHOE.color,
        icon: MODULE_TYPES.HORSESHOE.icon,
        start: new Date('September 07, 2020 14:00:00'),
        end: new Date('September 07, 2020 16:00:00'),
        details: 'O ferrador Mario vai colocar ferraduras para competição',
        timed: true
      },
      {
        name: 'Pé de Pano ira saltar',
        moduleName: MODULE_TYPES.SPORT.name,
        color: MODULE_TYPES.SPORT.color,
        icon: MODULE_TYPES.SPORT.icon,
        start: new Date('September 23, 2020 10:00:00'),
        end: new Date('September 23, 2020 17:00:00'),
        details: 'Competição de salto no cavaleiro',
        timed: true
      },
      {
        name: 'Corrida do Pé de Pano',
        moduleName: MODULE_TYPES.SPORT.name,
        color: MODULE_TYPES.SPORT.color,
        icon: MODULE_TYPES.SPORT.icon,
        start: new Date('September 27, 2020 10:00:00'),
        end: new Date('September 27, 2020 11:00:00'),
        details: 'Corrida no cavaleiro',
        timed: true
      },
      {
        name: 'Corrida do Pegasus',
        moduleName: MODULE_TYPES.SPORT.name,
        color: MODULE_TYPES.SPORT.color,
        icon: MODULE_TYPES.SPORT.icon,
        start: new Date('September 27, 2020 10:00:00'),
        end: new Date('September 27, 2020 11:00:00'),
        details: 'Corrida no cavaleiro',
        timed: true
      },
      {
        name: 'Corrida do Spirit',
        moduleName: MODULE_TYPES.SPORT.name,
        color: MODULE_TYPES.SPORT.color,
        icon: MODULE_TYPES.SPORT.icon,
        start: new Date('September 27, 2020 14:00:00'),
        end: new Date('September 27, 2020 15:00:00'),
        details: 'Corrida no cavaleiro',
        timed: true
      },
      {
        name: 'Trocar a ferradura do Pesadelo',
        moduleName: MODULE_TYPES.HORSESHOE.name,
        color: MODULE_TYPES.HORSESHOE.color,
        icon: MODULE_TYPES.HORSESHOE.icon,
        start: new Date('September 27, 2020 14:00:00'),
        end: new Date('September 27, 2020 16:00:00'),
        details: 'O ferrador Mario vai colocar ferraduras para competição',
        timed: true
      },
      {
        name: 'Visita veterinaria',
        moduleName: MODULE_TYPES.HORSESHOE.name,
        color: MODULE_TYPES.HORSESHOE.color,
        icon: MODULE_TYPES.HORSESHOE.icon,
        start: new Date('September 21, 2020 10:00:00'),
        end: new Date('September 21, 2020 12:00:00'),
        details: 'Tiago vai fazer um checkup dos animais que irao competir',
        timed: true
      },
      {
        name: 'Alimentacao para competicao',
        moduleName: MODULE_TYPES.FOOD.name,
        color: MODULE_TYPES.FOOD.color,
        icon: MODULE_TYPES.FOOD.icon,
        start: new Date('September 21, 2020 14:00:00'),
        end: new Date('September 21, 2020 16:00:00'),
        details: 'Suplementacao para competicao',
        timed: true
      }
    ]
  }),
  components: {
    Calendar
  },

  computed: {
    types() {
      return MODULE_TYPES;
    }
  }
});
