
import { CALENDAR_VIEW_TYPES } from "@/store/constants";

import Vue from "vue";
import { mdiChevronLeft, mdiChevronRight, mdiMenuDown } from "@mdi/js";
import { CalendarEvent } from "@/store/modules/calendar";

interface Props {
  events: CalendarEvent[];
  height: number;
  defaultType: string;
  defaultWeekDays: number[];
  showCalendarButtons: boolean;
}

interface Data {
  focus: string;
  typeToLabel: {
    month: string;
    week: string;
    day: string;
  };
  selectedEvent: CalendarEvent | Record<string, unknown>;
  selectedElement: HTMLElement | null;
  selectedOpen: boolean;
  calendarType: string;
  weekday: number[];
}

interface Methods {
  viewDay(date: string): void;
  getEventColor(event: CalendarEvent): string;
  setToday(): void;
  prev(): void;
  next(): void;
  showEvent(nativeEvent: unknown, event: CalendarEvent): void;
}

interface Computed {
  mdiChevronRight: string;
  mdiChevronLeft: string;
  mdiMenuDown: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  data: () => ({
    focus: '',
    typeToLabel: {
      'month': 'mês',
      'week': 'semana',
      'day': 'dia',
      '4day': '4 Dias'
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    calendarType: '',
    weekday: []
  }),
  async created() {
    this.calendarType = this.defaultType;
    this.weekday = this.defaultWeekDays;
  },
  computed: {
    mdiChevronLeft() {
      return mdiChevronLeft;
    },
    mdiChevronRight() {
      return mdiChevronRight;
    },
    mdiMenuDown() {
      return mdiMenuDown;
    }
  },
  mounted(): void {
    (
      this.$refs.calendar as Vue & {
        checkChange: () => void;
      }
    ).checkChange();
  },
  methods: {
    viewDay() {
      if (this.showCalendarButtons) {
        this.calendarType = CALENDAR_VIEW_TYPES.DAY;
      }
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = '';
    },
    prev() {
      (this.$refs.calendar as Vue & { prev: () => void }).prev();
    },
    next() {
      (this.$refs.calendar as Vue & { next: () => void }).next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    }
  },
  props: {
    events: {
      type: Array,
      required: true
    },
    height: {
      type: Number,
      default: 600
    },
    defaultType: {
      type: String,
      default: CALENDAR_VIEW_TYPES.MONTH
    },
    defaultWeekDays: {
      type: Array,
      default: () => [0, 1, 2, 3, 4, 5, 6]
    },
    showCalendarButtons: {
      type: Boolean,
      default: true
    }
  }
});
